<template>
    <div class="container mx-auto flex">
        <div class="columns-8xl">
            <div @click="addExtra('reoler')" id="first"
                class="hover:shadow-red-300 cursor-pointer active:scale-[.97] relative rounded-lg flex flex-col md:flex-row items-center md:shadow-xl md:h-72 mx-2 m-4">
                <div
                    class="z-0 order-1 md:order-2 relative w-full md:w-2/5 h-80 md:h-full overflow-hidden rounded-lg md:rounded-none md:rounded-r-lg">
                    <div class="absolute inset-0 w-full h-full object-center bg-blue-400 bg-opacity-30"
                        style="background-size: contain; background-position: center; background-repeat: no-repeat; background-image: url(storage_shelves.jpg); background-blend-mode: multiply;">
                    </div>
                    <div
                        class="md:hidden absolute inset-0 h-full p-6 pb-6 flex flex-col-reverse justify-start items-start bg-gradient-to-b from-transparent via-transparent to-gray-900">
                        <h3 class="w-full font-bold text-2xl text-white leading-tight mb-2">Tillegg
                        </h3>
                        <h4 class="w-full text-xl text-gray-100 leading-tight">Lagrings reoler</h4>
                    </div>
                    <svg class="hidden md:block absolute inset-y-0 h-full w-24 fill-current text-white -ml-12"
                        viewBox="0 0 100 100" preserveAspectRatio="none">
                        <polygon points="50,0 100,0 50,100 0,100" />
                    </svg>
                </div>
                <div class="z-10 order-2 md:order-1 w-full h-full md:w-3/5 flex items-center -mt-6 md:mt-0">
                    <div
                        class="p-8 md:pr-18 md:pl-14 md:py-12 mx-2 md:mx-0 h-full bg-white rounded-lg md:rounded-none md:rounded-l-lg shadow-xl md:shadow-none">
                        <h4 class="hidden md:block text-xl text-gray-400">Tillegg</h4>
                        <h3 class="hidden md:block font-bold text-2xl text-gray-700">Lagrings reoler</h3>
                        <p class="text-gray-600 text-justify">Lorem ipsum dolor sit amet consectetur,
                            adipisicing elit.
                            Ratione itaque perspiciatis quod sapiente quidem, vero consectetur quae iure error
                            tempore
                            reprehenderit unde veritatis fugit iusto nemo amet. Assumenda, quam facilis.</p>
                        <div v-bind:class="extras_selected['reoler'] ? 'extra_selected' : 'extra_not_selected'">
                            <div
                                className='<div class="px-6 py-4 transition-colors duration-200 transform rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700">'>
                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500"
                                        viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clip-rule="evenodd" />
                                    </svg>
                                    <span class="mx-4">Valgt</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div @click="addExtra('kabinetter')" id="first"
                class="hover:shadow-red-300 cursor-pointer active:scale-[.97] relative rounded-lg flex flex-col md:flex-row items-center md:shadow-xl md:h-72 mx-2 m-4">
                <div
                    class="z-0 order-1 md:order-2 relative w-full md:w-2/5 h-80 md:h-full overflow-hidden rounded-lg md:rounded-none md:rounded-r-lg">
                    <div class="absolute inset-0 w-full h-full object-center bg-blue-400 bg-opacity-30"
                        style="background-size: contain; background-position: center; background-repeat: no-repeat; background-image: url(cabinets.jpg); background-blend-mode: multiply;">
                    </div>
                    <div
                        class="md:hidden absolute inset-0 h-full p-6 pb-6 flex flex-col-reverse justify-start items-start bg-gradient-to-b from-transparent via-transparent to-gray-900">
                        <h3 class="w-full font-bold text-2xl text-white leading-tight mb-2">Tillegg
                        </h3>
                        <h4 class="w-full text-xl text-gray-100 leading-tight">Kabinetter</h4>
                    </div>
                    <svg class="hidden md:block absolute inset-y-0 h-full w-24 fill-current text-white -ml-12"
                        viewBox="0 0 100 100" preserveAspectRatio="none">
                        <polygon points="50,0 100,0 50,100 0,100" />
                    </svg>
                </div>
                <div class="z-10 order-2 md:order-1 w-full h-full md:w-3/5 flex items-center -mt-6 md:mt-0">
                    <div
                        class="p-8 md:pr-18 md:pl-14 md:py-12 mx-2 md:mx-0 h-full bg-white rounded-lg md:rounded-none md:rounded-l-lg shadow-xl md:shadow-none">
                        <h4 class="hidden md:block text-xl text-gray-400">Tillegg</h4>
                        <h3 class="hidden md:block font-bold text-2xl text-gray-700">Kabinetter</h3>
                        <p class="text-gray-600 text-justify">Lorem ipsum dolor sit amet consectetur,
                            adipisicing elit.
                            Ratione itaque perspiciatis quod sapiente quidem, vero consectetur quae iure error
                            tempore
                            reprehenderit unde veritatis fugit iusto nemo amet. Assumenda, quam facilis.</p>
                        <div v-bind:class="extras_selected['kabinetter'] ? 'extra_selected' : 'extra_not_selected'">
                            <div
                                className='<div class="px-6 py-4 transition-colors duration-200 transform rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700">'>
                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500"
                                        viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clip-rule="evenodd" />
                                    </svg>
                                    <span class="mx-4">Valgt</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div @click="addExtra('ellader')" id="first"
                class="hover:shadow-red-300 cursor-pointer active:scale-[.97] relative rounded-lg flex flex-col md:flex-row items-center md:shadow-xl md:h-72 mx-2 m-4">
                <div
                    class="z-0 order-1 md:order-2 relative w-full md:w-2/5 h-80 md:h-full overflow-hidden rounded-lg md:rounded-none md:rounded-r-lg">
                    <div class="absolute inset-0 w-full h-full object-center bg-blue-400 bg-opacity-30"
                        style="background-size: contain; background-position: center; background-repeat: no-repeat; background-image: url(ev_charging.jpg); background-blend-mode: multiply;">
                    </div>
                    <div
                        class="md:hidden absolute inset-0 h-full p-6 pb-6 flex flex-col-reverse justify-start items-start bg-gradient-to-b from-transparent via-transparent to-gray-900">
                        <h3 class="w-full font-bold text-2xl text-white leading-tight mb-2">Tillegg
                        </h3>
                        <h4 class="w-full text-xl text-gray-100 leading-tight">EL Bil tilkobling</h4>
                    </div>
                    <svg class="hidden md:block absolute inset-y-0 h-full w-24 fill-current text-white -ml-12"
                        viewBox="0 0 100 100" preserveAspectRatio="none">
                        <polygon points="50,0 100,0 50,100 0,100" />
                    </svg>
                </div>
                <div class="z-10 order-2 md:order-1 w-full h-full md:w-3/5 flex items-center -mt-6 md:mt-0">
                    <div
                        class="p-8 md:pr-18 md:pl-14 md:py-12 mx-2 md:mx-0 h-full bg-white rounded-lg md:rounded-none md:rounded-l-lg shadow-xl md:shadow-none">
                        <h4 class="hidden md:block text-xl text-gray-400">Tillegg</h4>
                        <h3 class="hidden md:block font-bold text-2xl text-gray-700">EL Bil tilkobling</h3>
                        <p class="text-gray-600 text-justify">Lorem ipsum dolor sit amet consectetur,
                            adipisicing elit.
                            Ratione itaque perspiciatis quod sapiente quidem, vero consectetur quae iure error
                            tempore
                            reprehenderit unde veritatis fugit iusto nemo amet. Assumenda, quam facilis.</p>
                        <div v-bind:class="extras_selected['ellader'] ? 'extra_selected' : 'extra_not_selected'">
                            <div
                                className='<div class="px-6 py-4 transition-colors duration-200 transform rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700">'>
                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500"
                                        viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clip-rule="evenodd" />
                                    </svg>
                                    <span class="mx-4">Valgt</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div @click="addExtra('lift')" id="first"
                class="hover:shadow-red-300 cursor-pointer active:scale-[.97] relative rounded-lg flex flex-col md:flex-row items-center md:shadow-xl md:h-72 mx-2 m-4">
                <div
                    class="z-0 order-1 md:order-2 relative w-full md:w-2/5 h-80 md:h-full overflow-hidden rounded-lg md:rounded-none md:rounded-r-lg">
                    <div class="absolute inset-0 w-full h-full object-center bg-blue-400 bg-opacity-30"
                        style="background-size: contain; background-position: center; background-repeat: no-repeat; background-image: url(car_lift.jpg); background-blend-mode: multiply;">
                    </div>
                    <div
                        class="md:hidden absolute inset-0 h-full p-6 pb-6 flex flex-col-reverse justify-start items-start bg-gradient-to-b from-transparent via-transparent to-gray-900">
                        <h3 class="w-full font-bold text-2xl text-white leading-tight mb-2">Tillegg
                        </h3>
                        <h4 class="w-full text-xl text-gray-100 leading-tight">Bil lift</h4>
                    </div>
                    <svg class="hidden md:block absolute inset-y-0 h-full w-24 fill-current text-white -ml-12"
                        viewBox="0 0 100 100" preserveAspectRatio="none">
                        <polygon points="50,0 100,0 50,100 0,100" />
                    </svg>
                </div>
                <div class="z-10 order-2 md:order-1 w-full h-full md:w-3/5 flex items-center -mt-6 md:mt-0">
                    <div
                        class="p-8 md:pr-18 md:pl-14 md:py-12 mx-2 md:mx-0 h-full bg-white rounded-lg md:rounded-none md:rounded-l-lg shadow-xl md:shadow-none">
                        <h4 class="hidden md:block text-xl text-gray-400">Tillegg</h4>
                        <h3 class="hidden md:block font-bold text-2xl text-gray-700">Bil lift</h3>
                        <p class="text-gray-600 text-justify">Lorem ipsum dolor sit amet consectetur,
                            adipisicing elit.
                            Ratione itaque perspiciatis quod sapiente quidem, vero consectetur quae iure error
                            tempore
                            reprehenderit unde veritatis fugit iusto nemo amet. Assumenda, quam facilis.</p>
                        <div v-bind:class="extras_selected['lift'] ? 'extra_selected' : 'extra_not_selected'">
                            <div
                                className='<div class="px-6 py-4 transition-colors duration-200 transform rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700">'>
                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500"
                                        viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clip-rule="evenodd" />
                                    </svg>
                                    <span class="mx-4">Valgt</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'RadioButtonGroup',
    select_extra: true,
    props : {
        extras_selected : Object
    },
    data() {
        return {
            selected: [],
            selectedOption: 50,
            isSelected: false,
            reoler: false,
            kabinetter: false,
            ellader: false,
            lift: false
        };
    },
    methods: {
        // isSelected: function () {
        //     return this.select_extra;
        // },
        addExtra: function (item) {
            this.$emit('extras_changed', item, !this.extras_selected[item]);
        }
    }
}

</script>

<style scoped>
.extra_selected {
    visibility: visible;
}

.extra_not_selected {
    visibility: hidden;
}

.container {
    display: flex;
    height: 100%;
    /* Ensure the container fills the height of its parent */
}

.left-column {
    flex: 0 0 30%;
    /* Set the left column to take up 30% of the container's width */
    background-color: #f0f0f0;
    /* Just for demonstration */
    padding: 20px;
    /* Add padding to the left column */
}

.right-column {
    flex: 1;
    /* Set the right column to take up the remaining space */
    background-color: #e0e0e0;
    /* Just for demonstration */
    padding: 20px;
    /* Add padding to the right column */
}
</style>