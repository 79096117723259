<!-- MainBody.vue -->
<template>
  <div class="main-body">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'MainBody'
}
</script>

<style scoped>
/* Add your main body styles here */
.main-body {
  padding: 20px;
}
</style>
