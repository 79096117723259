<template>
  <div id="app">
    <Header />
    <MainBody />
    <Footer />
  </div>
</template>

<script>
import Header from './views/Header.vue';
import MainBody from './views/MainBody.vue';
import Footer from './views/Footer.vue';

export default {
  name: 'App',
  components: {
    Header,
    MainBody,
    Footer
  }
}
</script>

<style>
/* Add global styles here */
</style>
