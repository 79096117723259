<template>
  <div class="max-w-xl mx-auto my-4 border-b-2 pb-4">
    <div class="flex pb-3">
      <div class="flex-1">
      </div>
      <template v-for="(name, key) in steps" v-bind:key="key">
        <div class="flex-1">
          <div
            class="w-10 h-10 bg-white border-2 border-grey-light mx-auto rounded-full text-lg text-blue-800 flex items-center">
            <span class="text-grey-darker text-center w-full">{{ key + 1 }}</span>
          </div>
        </div>
        <template v-if="key + 1 < steps.length">
          <div class="w-1/6 align-center items-center align-middle content-center flex">
            <div class="w-full bg-grey-light rounded items-center align-middle align-center flex-1">
              <template v-if="currentStep == key">
                <div class="active text-xs leading-none py-1 text-center text-grey-darkest rounded"></div>
              </template>
              <template v-else-if="currentStep < key">
                <div class="text-xs leading-none py-1 text-center text-grey-darkest rounded"></div>
              </template>
              <template v-else>
                <div class="completed_step text-xs leading-none py-1 text-center text-grey-darkest rounded"></div>
              </template>
            </div>
          </div>
        </template>
      </template>
      <div class="flex-1">
      </div>
    </div>
    <div class="flex text-xs content-center text-center">
      <div v-for="(name, key) in steps" v-bind:key="key" class="w-1/4">
        {{ name }}
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'HorizontalStepper',
  props: {
    steps: Array, // Array of step titles
    currentStep: Number // Index of the currently active step
  },
  methods: {
    updateStep(step) {
      this.$emit('changed-step', step);
    },
    nextStep() {
      if (this.currentStep + 1 < (this.steps.length)) {
        this.updateStep(this.currentStep + 1);
      }
    },
    prevStep() {
      if (this.currentStep - 1 >= 0) {
        this.updateStep(this.currentStep - 1);
      }
    }
  },
  computed: {
    progress_stepper: function () {
      return "active";
    }
  }
}
</script>

<style scoped>
.step {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.active {
  border: 2px solid;
  border-color: lightblue;
  background-color: lightblue;
  width: 20%;
}

.completed_step {
  border: 2px solid;
  border-color: lightblue;
  background-color: lightblue;
  width: 100%
}
.step-number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #007bff;
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-title {
  margin-top: 10px;
}
</style>