<!-- Footer.vue -->
<template>
    <footer>
      <ul class="social-links">
        <li><a href="#">Facebook</a></li>
        <li><a href="#">Twitter</a></li>
        <li><a href="#">Instagram</a></li>
        <!-- Add more social media links as needed -->
      </ul>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterPage'
  }
  </script>
  
  <style scoped>
  /* Add your footer styles here */
  footer {
    padding: 20px;
    background-color: #333;
    color: white;
  }
  
  .social-links {
    list-style: none;
    padding: 0;
    display: flex;
  }
  
  .social-links li {
    margin-right: 10px;
  }
  
  .social-links li a {
    color: white;
    text-decoration: none;
  }
  </style>
  