// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import MainBody from './views/MainBody.vue'; // Create this component
import ProductBuild from '@/views/ProductBuild'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: MainBody
  },
  {
    path: '/productbuild',
    name: 'ProductBuild',
    component: ProductBuild
  },
  // You can add more routes here for other pages
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
