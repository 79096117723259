<!-- Header.vue -->
<template>
  <!-- <header>
      <div class="logo">Your Logo Here</div>
      <nav>
        <ul>
          
          <li><a href="#">Link 3</a></li>
           Add more links as needed 
        </ul>
      </nav>
    </header> -->

  <header class="bg-gray-800">
    <nav class="container mx-auto px-6 py-3">
      <div class="flex items-center justify-between">
        <div class="text-white font-bold text-xl">
          <a href="#">Build your Space</a>
        </div>
        <div class="hidden md:block">
          <ul class="flex items-center space-x-8">
            <li><router-link class="text-white" to="/">Home</router-link></li>
            <li><router-link class="text-white" to="/productbuild">Product Build</router-link></li>
          </ul>
        </div>
        <div class="md:hidden">
          <button class="outline-none mobile-menu-button">
            <svg class="w-6 h-6 text-white" x-show="!showMenu" fill="none" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </div>
      <div class="mobile-menu hidden md:hidden">
        <ul class="mt-4 space-y-4">
          <li><router-link class="text-white" to="/">Home</router-link></li>
          <li><router-link class="text-white" to="/productbuild">Product Build</router-link></li>
        </ul>
      </div>
    </nav>
  </header>

</template>

<script>
export default {
  name: "HeaderPage"
}
</script>

<style scoped>
/* Add your header styles here */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: white;
}

.logo {
  font-size: 24px;
}
</style>